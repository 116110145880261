import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import "./header.css"
import image from "../images/logo.png"
import close from "../images/close.svg"

const boldIfTrue = (bold, content) =>
  bold ? (
    <span style={{ fontFamily: "BrandonGrotesque-Bold" }}>{content}</span>
  ) : (
    content
  )

const Hamburger = () => (
  <div className="hamburger-container">
    <div className="hamburger-line" />
    <div className="hamburger-line" />
    <div className="hamburger-line" />
  </div>
)

const Header = ({ title, page }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <header className="header only-desktop">
        <div className="flex-grid" style={{ height: "150px" }}>
          <div style={{ flex: "1" }}></div>
          <div style={{ flex: "0 0 102px" }}>
            <div className="vertical-center">
              <Link to="" style={{ textDecoration: "none" }}>
                <img
                  src={image}
                  height="95px"
                  width="95px"
                  alt="Neon Coat Logo"
                  style={{ marginTop: "10px" }}
                />
                <div className="logo-name">NEON COAT</div>
              </Link>
            </div>
          </div>
          <div style={{ flex: "0 0 900px" }}>
            <div
              className="vertical-center"
              style={{
                float: "right",
                marginRight: "0",
              }}
            >
              <Link to="/about" className="header-link">
                {boldIfTrue(page === "about", "ABOUT")}
              </Link>
              <Link to="/brands" className="header-link">
                {boldIfTrue(page === "brands", "BRANDS")}
              </Link>
              <Link to="/models" className="header-link">
                {boldIfTrue(page === "models", "MODELS")}
              </Link>
              <Link to="/press" className="header-link">
                {boldIfTrue(page === "press", "PRESS")}
              </Link>
              <Link to="/contact" className="header-link">
                {boldIfTrue(page === "contact", "CONTACT")}
              </Link>
              <a
                href="https://brands.neoncoat.com"
                className="header-link header-login"
                target="_blank"
              >
                LOG IN
              </a>
              <Link to="/brands" className="header-link header-start">
                <b>GET STARTED</b>
              </Link>
            </div>
          </div>
          <div style={{ flex: "1" }}></div>
        </div>
      </header>
      <div style={{ height: "150px" }} className="only-desktop" />
      <header className="header only-mobile-tablet">
        <div className="flex-grid">
          <div
            className={`${isOpen ? "only-desktop" : "only-mobile-tablet"}`}
            style={{ flex: "1" }}
          >
            <div onClick={() => setOpen(!isOpen)}>
              {isOpen ? (
                <img className="hamburger-container" src={close} />
              ) : (
                <Hamburger />
              )}
            </div>
          </div>
          <div
            className={` ${
              isOpen ? "only-mobile-tablet" : "only-desktop"
            } tablet-top-menu`}
          >
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setOpen(!isOpen)}
                style={{ flex: "0 0 100px" }}
              >
                {isOpen ? (
                  <img className="hamburger-container" src={close} />
                ) : (
                  <Hamburger />
                )}
              </div>
              <div style={{ flex: "0 0 201px" }} className="only-tablet">
                <Link to="" style={{ textDecoration: "none" }}>
                  <img
                    src={image}
                    height="40px"
                    width="40px"
                    alt="Neoncoat logo"
                    style={{ marginTop: "15px", marginLeft: "80px" }}
                  />
                  <div className="logo-name" style={{ marginTop: "-6px" }}>
                    NEON COAT
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div style={{ flex: "1" }}>
            <div
              className={`vertical-center ${
                isOpen ? "only-desktop-mobile" : ""
              }`}
            >
              <Link to="" style={{ textDecoration: "none" }}>
                <img
                  src={image}
                  width="40px"
                  height="40px"
                  alt="Neoncoat logo"
                  className="logo-image"
                  style={{ marginTop: "10px" }}
                />
                <div className="logo-name">NEON COAT</div>
              </Link>
            </div>
          </div>
          <div style={{ flex: "1" }}>
            <div
              className="vertical-center"
              style={{ display: isOpen ? "none" : "block" }}
            >
              <Link to="/brands" className="header-link header-start">
                <b>GET STARTED</b>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div
        style={{ display: isOpen ? "block" : "none" }}
        className="mobile-menu only-mobile-tablet"
      >
        <Link to="brands">
          <div className="mobile-menu-item get-started-mobile">GET STARTED</div>
        </Link>
        <Link to="about">
          <div className="mobile-menu-item">ABOUT</div>
        </Link>
        <Link to="brands">
          <div className="mobile-menu-item">BRANDS</div>
        </Link>
        <Link to="models">
          <div className="mobile-menu-item">MODELS</div>
        </Link>
        <Link to="press">
          <div className="mobile-menu-item">PRESS</div>
        </Link>
        <Link to="contact">
          <div className="mobile-menu-item">CONTACT</div>
        </Link>
        <a href="https://brands.neoncoat.com" target="_blank">
          <div className="mobile-menu-item">LOG IN</div>
        </a>
      </div>
      <div style={{ height: "75px" }} className="only-mobile-tablet" />
    </>
  )
}

Header.propTypes = {
  title: PropTypes.string,
}

Header.defaultProps = {
  title: ``,
}

export default Header
