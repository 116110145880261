import { Link } from "gatsby"
import React from "react"
import "./footer.css"
import instagram from "../images/instagram.svg"
import youtube from "../images/youtube.svg"
import twitter from "../images/twitter.svg"
import facebook from "../images/facebook.svg"
import models from "../images/models-icon.png"
import brands from "../images/brands-icon.png"

const Footer = () => (
  <>
    <div className="footer">
      <div className="footer-width">
        <div
          className="flex-grid footer-container "
          style={{ maxWidth: "100vw" }}
        >
          <div
            style={{ flex: "2 2 500px" }}
            className="only-desktop-tablet footer-c"
          >
            <Link to="terms_brands" className="footer-text">
              Terms of use
            </Link>
            <Link to="privacy_policy" className="footer-text">
              Privacy policy
            </Link>
            <Link to="faq" className="footer-text">
              FAQ
            </Link>
            <div
              className="footer-text only-desktop-tablet"
              style={{ marginTop: "6px" }}
            >
              <a href="mailto:info@neoncoat.com">
                <span style={{ color: "#ffffff", textDecoration: "underline" }}>
                  info@neoncoat.com
                </span>
              </a>{" "}
              |{" "}
              <a
                href="tel:+13477099134"
                style={{ color: "#ffffff", textDecoration: "underline" }}
              >
                (347) 709-9134
              </a>
            </div>
            <br />
          </div>
          <div style={{ flex: "2 2 500px" }} className="only-mobile">
            <div
              className="flex-grid"
              style={{
                width: "200px",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              {" "}
              <div style={{ flex: "2" }}>
                {" "}
                <Link to="terms_models" className="footer-text">
                  Terms of use
                </Link>
              </div>
              <div style={{ flex: "2" }}>
                <Link to="privacy_policy" className="footer-text">
                  Privacy policy
                </Link>
              </div>
            </div>
          </div>
          <div className="social-links">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/neoncoat/"
            >
              <img src={instagram} alt="Neon Coat Instagram" className="social-link" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCDdjM8Llf_7R3aqCfBABAEg/"
            >
              <img src={youtube} alt="Neon Coat YouTube" className="social-link" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/Neon_Coat"
            >
              <img src={twitter} alt="Neon Coat Twitter" className="social-link" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/Neon-Coat-1027708807340011/"
            >
              <img src={facebook} alt="Neon Coat Facebook" className="social-link" />
            </a>
          </div>
          <div className="app-icon-container">
            <a href="/modelApp" target="_blank">
              <div className="app-icon">
                <img src={models} alt="Neon Coat App for Models" className="footer-app-icon" />
                <div className="app-icon-text">Neon Coat</div>
              </div>
            </a>
            <a href="/brandsApp" target="_blank">
              <div className="app-icon">
                <img src={brands} alt="Neon Coat App for Brands" className="footer-app-icon" />
                <div className="app-icon-text">NC Brands</div>
              </div>
            </a>
          </div>
          <div
            style={{ flex: "1 1 250px", textAlign: "center" }}
            className="footer-text only-mobile"
          >
            <a href="mailto:info@neoncoat.com">
              <span style={{ color: "#ffffff", textDecoration: "underline" }}>
                info@neoncoat.com
              </span>
            </a>{" "}
            <br />
            <br />
            <a
              href="tel:+13477099134"
              style={{ color: "#ffffff", textDecoration: "underline" }}
            >
              (347) 709-9134
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="only-mobile" style={{ height: "235px" }} />
  </>
)
//<div style={{ height: "195px" }}></div>
export default Footer
